<template>
  <div class="category-tree">
    <esmp-collapser
      v-if="!checklistFormId"
      title="Каталог услуг"
      :is-expanded.sync="isExpanded"
    >
      <div class="category-tree__view">
        <div
          v-for="view in CATEGORY_TREE_VIEW_TYPES"
          :class="['category-tree__view-item', { 'category-tree__view-item--active' : activeView === view }]"
          :key="view"
          @click="setActiveView(view)"
        >
          <esmp-icon :name="getViewIcon(view)" />
        </div>
      </div>
      <template v-if="activeView === CATEGORY_TREE_VIEW_TYPES.line">
        <div class="category-tree__row" v-if="isLoadedServiceTypes">
          <div class="category-tree__item">
            <esmp-select
              :value="firstLevel ? firstLevel.id : null"
              placeholder="Тип услуги"
              :disabled="isFormDisabled"
              filterable
              @on-change="updateLevel('firstLevel', $event)"
            >
              <esmp-select-option
                v-for="item in serviceTypes"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
          <div class="category-tree__item">
            <esmp-select
              :value="secondLevel ? secondLevel.id : null"
              placeholder="Категория услуги"
              :disabled="isFormDisabled || !serviceCategories.length"
              filterable
              @on-change="updateLevel('secondLevel', $event)"
            >
              <esmp-select-option
                v-for="item in serviceCategories"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
          <div class="category-tree__item">
            <esmp-select
              :value="thirdLevel ? thirdLevel.id : null"
              placeholder="Группа услуги"
              :disabled="isFormDisabled || !serviceGroups.length"
              filterable
              @on-change="updateLevel('thirdLevel', $event)"
            >
              <esmp-select-option
                v-for="item in serviceGroups"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
          <div class="category-tree__item">
            <esmp-select
              :value="fourthLevel ? fourthLevel.name : null"
              placeholder="Услуга"
              :disabled="isFormDisabled || !services.length"
              filterable
              @on-change="updateLevel('fourthLevel', $event)"
            >
              <esmp-select-option
                v-for="item in sortedServices"
                :value="item.name"
                :key="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>
      </template>
      <template v-if="activeView === CATEGORY_TREE_VIEW_TYPES.card && isLoadedServiceTypes">
        <esmp-collapser
          title="Тип услуги"
          :is-expanded.sync="isLevelsExpanded.firstLevel"
          class="category-tree__cards-collapser"
        >
          <div class="category-tree__cards">
            <div
              :class="['category-tree__card', {'category-tree__card--active': firstLevel && item.id === firstLevel.id}]"
              v-for="item in serviceTypes"
              :key="item.id"
              @click="!isFormDisabled ? updateLevel('firstLevel', item.id): ''"
            >
              <h4 class="category-tree__card-title">
                {{ item.name }}
              </h4>
              <esmp-tag
                icon="24-portfolio"
                color="purple"
              />
            </div>
          </div>
        </esmp-collapser>
        <esmp-collapser
          v-if="serviceCategories.length"
          title="Категория услуги"
          :is-expanded.sync="isLevelsExpanded.secondLevel"
          class="category-tree__cards-collapser"
        >
          <div class="category-tree__cards">
            <div
              :class="[
                'category-tree__card',
                {'category-tree__card--active': secondLevel && item.id === secondLevel.id}
              ]"
              v-for="item in serviceCategories"
              :key="item.id"
              @click="updateLevel('secondLevel', item.id)"
            >
              <h4 class="category-tree__card-title">
                {{ item.name }}
              </h4>
              <esmp-tag
                icon="24-portfolio"
                color="purple"
              />
            </div>
          </div>
        </esmp-collapser>
        <esmp-collapser
          v-if="serviceGroups.length"
          title="Группа услуги"
          :is-expanded.sync="isLevelsExpanded.thirdLevel"
          class="category-tree__cards-collapser"
        >
          <div class="category-tree__cards">
            <div
              :class="['category-tree__card', {'category-tree__card--active': thirdLevel && item.id === thirdLevel.id}]"
              v-for="item in serviceGroups"
              :key="item.id"
              @click="updateLevel('thirdLevel', item.id)"
            >
              <h4 class="category-tree__card-title">
                {{ item.name }}
              </h4>
              <esmp-tag
                icon="24-portfolio"
                color="purple"
              />
            </div>
          </div>
        </esmp-collapser>
        <esmp-collapser
          v-if="services.length"
          title="Услуга"
          :is-expanded.sync="isLevelsExpanded.fourthLevel"
          class="category-tree__cards-collapser"
        >
          <div class="category-tree__cards">
            <div
              :class="[
                'category-tree__card',
                {'category-tree__card--active': fourthLevel && item.id === fourthLevel.id}
              ]"
              v-for="item in sortedServices"
              :key="item.id"
              @click="updateLevel('fourthLevel', item.id)"
            >
              <h4 class="category-tree__card-title">
                {{ item.name }}
              </h4>
              <esmp-tag
                icon="24-portfolio"
                color="purple"
              />
            </div>
          </div>
        </esmp-collapser>
      </template>
    </esmp-collapser>
  </div>
</template>

<script>
import { getCreateTicketPath } from '@/helpers/pathGenerator';
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { TREE_LEVELS, CATEGORY_TREE_VIEW_TYPES } from '@/constants/ticket';

const levelMap = {
  firstLevel: 'serviceTypes',
  secondLevel: 'serviceCategories',
  thirdLevel: 'serviceGroups',
  fourthLevel: 'services',
};

export default {
  name: 'CategoryTree',
  props: {
    isFormDisabled: {
      type: Boolean,
      default: false,
    },
    hasCurrentService: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadedServiceTypes: false,
      isExpanded: !this.hasCurrentService,
      activeView: 'line',
      isLevelsExpanded: {
        firstLevel: true,
        secondLevel: false,
        thirdLevel: false,
        fourthLevel: false,
      },
    };
  },
  computed: {
    ...mapState('services', [
      'serviceTypes',
      'serviceCategories',
      'serviceGroups',
      'services',
    ]),
    ...mapState('user', ['selectedUser']),
    ...mapState('ticket', ['tree']),
    ...mapGetters('services', ['sortedServices']),
    serviceId() {
      return this.$route.params.serviceId;
    },
    checklistFormId() {
      return this.$route.query.formId;
    },
    firstLevel: {
      get() {
        return this.tree.firstLevel;
      },
      set(newValue) {
        if (newValue) {
          this.setTreeElement({
            level: TREE_LEVELS.indexOf('secondLevel'),
            value: null,
          });
          this.setTreeElement({
            level: TREE_LEVELS.indexOf('firstLevel'),
            value: newValue,
          });
          this.fetchServiceCategory().catch((err) => {
            this.setTreeElement({
              level: TREE_LEVELS.indexOf('firstLevel'),
              value: null,
            });
            return err;
          });
          this.isLevelsExpanded.firstLevel = false;
          this.isLevelsExpanded.thirdLevel = false;
          this.isLevelsExpanded.fourthLevel = false;
          this.isLevelsExpanded.secondLevel = true;
        }
      },
    },
    secondLevel: {
      get() {
        return this.tree.secondLevel;
      },
      set(newValue) {
        if (newValue) {
          this.setTreeElement({
            level: TREE_LEVELS.indexOf('thirdLevel'),
            value: null,
          });
          this.setTreeElement({
            level: TREE_LEVELS.indexOf('secondLevel'),
            value: newValue,
          });
          this.fetchServiceGroup().catch((err) => {
            this.setTreeElement({
              level: TREE_LEVELS.indexOf('secondLevel'),
              value: null,
            });
            return err;
          });
          this.isLevelsExpanded.firstLevel = false;
          this.isLevelsExpanded.secondLevel = false;
          this.isLevelsExpanded.fourthLevel = false;
          this.isLevelsExpanded.thirdLevel = true;
        }
      },
    },
    thirdLevel: {
      get() {
        return this.tree.thirdLevel;
      },
      set(newValue) {
        if (newValue) {
          this.setTreeElement({
            level: TREE_LEVELS.indexOf('fourthLevel'),
            value: null,
          });
          this.setTreeElement({
            level: TREE_LEVELS.indexOf('thirdLevel'),
            value: newValue,
          });
          this.fetchService().catch((err) => {
            this.setTreeElement({
              level: TREE_LEVELS.indexOf('thirdLevel'),
              value: null,
            });
            return err;
          });
          this.isLevelsExpanded.firstLevel = false;
          this.isLevelsExpanded.secondLevel = false;
          this.isLevelsExpanded.thirdLevel = false;
          this.isLevelsExpanded.fourthLevel = true;
        }
      },
    },
    fourthLevel: {
      get() {
        return this.tree.fourthLevel;
      },
      set(newValue) {
        if (newValue && newValue.id) {
          if (newValue.id === this.tree.fourthLevel.id) return;
          this.setTreeElement({
            level: TREE_LEVELS.indexOf('fourthLevel'),
            value: newValue,
          });
          this.isLevelsExpanded.firstLevel = false;
          this.isLevelsExpanded.secondLevel = false;
          this.isLevelsExpanded.thirdLevel = false;
          this.isLevelsExpanded.fourthLevel = false;

          const { id } = this.tree.fourthLevel;
          const sourceByService = this.tree.fourthLevel.source;
          const { serviceId, templateId, source } = this.$route.params;
          if (id !== serviceId) {
            const params = {
              source: sourceByService || source || 'sm1',
              serviceId: id,
            };
            if (templateId) params.templateId = templateId;
            if (id) {
              this.$router.push({
                path: getCreateTicketPath(id, sourceByService),
              });
            }
            this.$emit('get-checklist');
          }
        }
      },
    },
  },
  watch: {
    serviceTypes() {
      if (this.serviceTypes?.length === 1 && !this.hasCurrentService) {
        [this.firstLevel] = this.serviceTypes;
      }
    },
    serviceCategories() {
      if (this.serviceCategories?.length === 1 && !this.hasCurrentService) {
        [this.secondLevel] = this.serviceCategories;
      }
    },
    serviceGroups() {
      if (this.serviceGroups?.length === 1 && !this.hasCurrentService) {
        [this.thirdLevel] = this.serviceGroups;
      }
    },
    services() {
      if (this.services?.length === 1 && !this.hasCurrentService) {
        [this.fourthLevel] = this.services;
      }
    },
    selectedUser() {
      this.checkTreeAvailabilityForUser(true);
    },
    serviceId(val) {
      if (!val) {
        this.setTreeElement({
          level: 0,
          value: null,
        });
        this.clearServices();
      }
    },
    hasCurrentService() {
      this.isExpanded = !this.hasCurrentService;
    },
  },
  methods: {
    ...mapMutations('ticket', [
      'SET_TREE_ELEMENT',
    ]),
    ...mapActions('services', [
      'fetchServiceTypes',
      'fetchServiceCategory',
      'fetchServiceGroup',
      'fetchService',
      'fetchStructure',
      'clearServices',
    ]),
    ...mapActions('ticket', ['setTreeElement']),
    updateLevel(name, id) {
      this[name] = this[levelMap[name]].find((el) => el.name === id || el.id === id) || null;
    },
    checkTreeAvailabilityForUser(hasRedirect) {
      const { serviceId, source } = this.$route.params;
      if (serviceId && source) {
        this.fetchStructure({ serviceId, source })
          .then(async () => {
            this.isLoadedServiceTypes = true;
            this.$emit('get-checklist');
          })
          .catch((err) => {
            if (hasRedirect) {
              this.setTreeElement({
                level: 0,
                value: null,
              });
              this.$router.push({
                name: 'CreateTicket',
              });
            }
            return err;
          });
      } else {
        this.setTreeElement({
          level: 0,
          value: null,
        });
        this.fetchServiceTypes().then(() => {
          this.isLoadedServiceTypes = true;
        });
      }
    },
    setActiveView(view) {
      this.activeView = view;
    },
    getViewIcon(view) {
      return view === 'card' ? '24-grid-columns-3' : '24-grid-columns-1';
    },
  },
  created() {
    this.CATEGORY_TREE_VIEW_TYPES = CATEGORY_TREE_VIEW_TYPES;
    if (!this.checklistFormId) {
      this.checkTreeAvailabilityForUser();
    }
  },
  beforeDestroy() {
    this.setTreeElement({
      level: 0,
      value: null,
    });
    this.isLoadedServiceTypes = false;
    this.clearServices();
  },
};
</script>

<style lang="scss">
.category-tree {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  @include for-size(phone-portrait-down) {
    margin-bottom: 12px;
  }
  &__head {
    margin-bottom: 16px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include for-size(phone-portrait-down) {
      flex-direction: column;
    }
  }

  &__item {
    width: calc(50% - 12px);
    margin-bottom: 24px;

    @include for-size(phone-portrait-down) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .esmp-select-disabled {
    opacity: .5;
    .esmp-select-head {
      background-color: $color-grayscale-40;
    }
  }

  .esmp-select-item {
    white-space: unset;
  }

  &__view {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $base-gutter;
    color: $color-black;
    transition: color $base-animation-time;
    margin-top: -45px;

    @include for-size(phone-portrait-down) {
      margin-top: 0;
    }
  }
  &__view-item {
    & + & {
      margin-left: $base-gutter;
    }

    &:hover,
    &--active {
      color: $primary-color-hover;
      cursor: pointer;
    }
  }
  &__cards-collapser {
    margin-bottom: $base-gutter;
  }
  &__cards {
    display: grid;
    gap: $gap / 2;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: max-content;
    margin-bottom: $base-gutter;

    @include for-size(tablet-landscape-down) {
      grid-template-columns: 1fr 1fr;
    }

    @include for-size(phone-landscape-down) {
      grid-template-columns: 100%;
      gap: $gap / 2;
    }
  }

  &__card {
    border-radius: $base-border-radius * 2;
    padding: $gap / 2;
    border: 1px solid $primary-color;
    cursor: pointer;
    display: flex;
    gap: $base-gutter;
    align-items: flex-start;

    @include for-size(phone-portrait-down) {
      padding: 16px;
      min-height: 152px;
      .esmp-svg-icon {
        width: 48px;
        height: 48px;
        flex-direction: column-reverse;
      }
    }
    &:hover {
      transition: box-shadow $base-animation;
      box-shadow: $base-shadow-m;
    }
    &--active {
      background-color: rgba(var(--esmp-ui-primary-color-rgb), 0.12);
    }
  }
  &__card-title {
    flex-grow: 1;
    padding-top: 4px;
    color: $color-text;
    font-weight: 700;
    @include for-size(phone-portrait-down) {
      font-size: 14px;
      padding: 0;
      margin-top: 12px;
    }
  }
}
</style>
